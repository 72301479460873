@charset "UTF-8";

/* common ---------------------- */
:root {
  --main-text-color: #2c2c2c;
  --main-position: center;
  --main-lh: 1.5;
}
html{
    font-size: 62.5%;
}
body {
    background-color: #EEF0F2;
    color: var(--main-text-color);
    font-size: 1.4rem;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1.7;
    letter-spacing: 0.16rem;
    opacity: 0;
    animation: fade-in 2s ease 0s 1 normal forwards;
    -webkit-animation: fade-in 2s ease 0s 1 normal forwards;
    a{
        color:#999999;
        text-decoration: none;
        &:visited{
            color:#999999;
            text-decoration: none;
        }
        &:hover{
            color:#333333;
            text-decoration: none;
        }
    }
    .container{
        // width: 1140px;
        width: 960px;
        margin: 0 auto;
    }
    .hidden{
        display: none;
    }
    // .viewbutton{
    //     width: 235px;
    //     height: 50px;
    //     margin: 0 auto;
    //     a:link{
    //         background-color: #555555;
    //         height: 50px;
    //         display: block;
    //         color: #FFFFFF;
    //         line-height: 3.5;
    //         &:hover{
    //             text-decoration: none;
    //             background-color: #999999;
    //         }
    //     }
    //     i{
    //         padding: 0 0.4em;
    //     }
    // }

    /* Animation */
    @keyframes fade-in {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    @-webkit-keyframes fade-in {
        0% {opacity: 0}
        100% {opacity: 1}
    }
    .fade-in{
        animation-name: fade-in;
        animation-duration:1s;
        animation-fill-mode: forwards;
    }
}



/* #page-header ------------------------*/
#page-header{
    width: 100%;
    position: relative;
    padding: 20px 60px 0;
    margin: 0 0 100px;
    display: flex;
    letter-spacing: 0.2rem;
    z-index: 200;
    .site-id{
        width: 400px;
        font-size: 1.8rem;
        font-weight: 400;
        letter-spacing: 0.2rem;
        span a{
            margin-left: 16px;
            font-size: 1.2rem;
            color: #666;
        }
    }
    nav{
        width: 200px;
        margin-left: auto;
        .header-global-nav{
            ul{
                display: flex;
                justify-content: space-between;
                li{
                    margin-left: 24px;
                    text-transform: uppercase;
                    a{
                        padding-bottom: 4px;
                    }
                    // a.current{
                    //     color: #666;
                    //     border-bottom: 1px solid #666;
                    // }
                }
                .header-nav-sns{
                    font-size: 1.8rem;
                }
            }
        }
    }
    // .main-arrow{
    //     position: absolute;
    //     bottom: 0%;
    //     left: 50%;
    //     -ms-transform: translate(-50%, -50%);
    //     -webkit-transform: translate(-50%, -50%);
    //     transform: translate(-50%, -50%);
    //     text-align: center;
    //     color: #FFFFFF;
    //     font-size: 2.4rem;
    //     width: 2.4rem;
    // }
}

@media (max-width:767px){
    #page-header{
        text-align: center;
        // padding: 20px 60px 0;
        margin: 0 0 64px;
        display: block;
        // letter-spacing: 0.2rem;
        .site-id{
            width: 100%;
            font-size: 1.8rem;
            span a{
                margin-left: 0;
                font-size: 1.2rem;
            }
        }
    }
    nav{
        display: none;
        width: 100%;
        margin-left: 0;
        .header-global-nav{
            ul{
                display: block;
                li{
                    margin-left: 0;
                    a{
                        padding-bottom: 0;
                    }
                }
                .header-nav-sns{
                    display: none;
                }
            }
        }
    }
}



/* #home-profile ------------------------*/
#home-profile{
    font-size: 1.4rem;
    position: relative;
    width: 100%;
    z-index: 10;
    margin: 0 0 100px;
    h2{
        color: #CCC;
        text-align: center;
        text-transform: uppercase;
        font-size: 2rem;
        letter-spacing: 0.4rem;
    }
    .container{
        margin: 60px auto 0;
        h3{
            font-size: 1.4rem;
            text-align: left;
            margin-bottom: 40px;
        }
        p{
            color: var(--main-text-color);
            margin-bottom: 40px;
        }
        ul{
            color: var(--main-text-color);
            margin-bottom: 40px;
        }
    }
}
@media (max-width:767px){
    #home-profile{
        margin: 0 0 64px;
        .container{
            width: 100%;
            padding: 0 16px;
            margin: 60px auto 0;
            h3{
                text-align: center;
                margin-bottom: 32px;
            }
            p{
                margin-bottom: 40px;
                font-size: 1.2rem;
            }
            ul{
                margin-bottom: 40px;
                font-size: 1.2rem;
            }
        }
    }
}



/* #home-works ------------------------*/
#home-works{
    position: relative;
    width: 100%;
    z-index: 11;
    margin: 0 0 160px;
    h2{
        margin:0;
        color: #CCC;
        text-align: center;
        text-transform: uppercase;
        font-size: 2rem;
        letter-spacing: 0.4rem;
    }
    .container{
        width: 960px;
        margin: 60px auto 0;
        p{
            color: var(--main-text-color);
            margin-bottom: 40px;
        }
        ul{
            color: var(--main-text-color);
        }
        h3{
            font-size: 1.4rem;
            margin-bottom: 10px;
        }
    }
}

@media (max-width:767px){
    #home-works{
        margin: 0 0 64px;
        .container{
            width: 100%;
            padding: 0 16px;
            margin: 32px auto 0;
           h3{
                text-align: center;
                margin-bottom: 32px;
            }
            p{
                margin-bottom: 40px;
                font-size: 1.2rem;
            }
            ul{
                margin-bottom: 40px;
                font-size: 1.2rem;
            }
        }
    }
}


#canvas-container{
    width: 100%;
    height: 100vh;
    position: fixed;
    left:0;
    top:0;
    height: 100vh;
    z-index: 1;
}




//
// /* works --------------------------*/
// #works{
//     background-color: #f2f2f2;
//     padding: 100px 0;
//     text-align: center;
// }
// #works h2{
//     font-size: 2.4em;
//     font-weight: 400;
//     margin-bottom: 20px;
// }
// .work-subtitle{
//     font-size: 1.2em;
//     font-weight: 200;
// }
// #works .container{
//     margin: 50px auto;
//     display: flex;
//     justify-content: space-between;
// }
// #works section{
//     width: 280px;
//     box-sizing: border-box;
//     background-color: #FFFFFF;
//     padding: 35px 16px 35px;
// }
// #works h3{
//     font-size: 1.8em;
//     font-weight: 400;
//     line-height: 1;
// }
// #works section .work-photo {
//     width: 180px;
//     height: 180px;
//     border-radius: 90px;
//     overflow: hidden;
//     margin: 30px auto;
// }
// #works section p {
//     line-height: 1.8;
// }


/* column --------------------------*/
// .column{
//     background-color: #f4f4f4;
//     padding: 80px 0;
//     text-align: center;
//     h2{
//         font-size: 2.4rem;
//         font-weight: 400;
//         margin-bottom: 24px;
//     }
//     .container{
//         margin: 48px auto;
//         display: flex;
//         justify-content: space-between;
//         flex-wrap: wrap;
//         section{
//             width: 360px;
//             height: 420px;
//             box-sizing: border-box;
//             background-color: #fff;
//             text-align: left;
//             margin:0 10px 40px;
//             .column-photo {
//                 width: 100%;
//                 height: 240px;
//                 overflow: hidden;
//             }
//             .column-inner{
//                 padding: 24px 16px 0;
//                 position: relative;
//                 h3{
//                     font-size: 1.2rem;
//                     font-weight: 400;
//                     line-height: 1;
//                     margin-bottom: 18px;
//                 }
//                 p {
//                     font-size: 0.85rem;
//                     line-height: 1.8;
//                 }
//                 .column-category{
//                     padding: 4px 12px;
//                     position: absolute;
//                     right: 16px;
//                     top: 18px;
//                     background-color: #f85019;
//                     color:#fff;
//                     border-radius: 2px;
//                 }
//             }
//         }
//     }
// }


/* footer --------------------------*/
footer{
    font-size: 1.4rem;
    background-color: #1d1d1d;
    position: relative;
    z-index: 100;
    .container{
        padding: 60px 0;
        p{
            text-align: center;
            color:#FFFFFF;
        }
    }
}
@media (max-width:767px){
    footer{
        .container{
            font-size: 0.9rem;
            width: 100%;
            padding: 32px 0;
        }
    }
}
